<template>
  <div class="page">
    <!--pages/myinfo/myinfo.wxml-->
    <div class="head">
      <div class="user flexcenter">
        <img class="user-img"  src="~images/index/haowu-cmh.png">
        <div class="r1">
          <div class="name">{{userInfo.nickname}}</div>
          <div class="id flexcenter">
            <span>ID: {{userInfo.uid}}</span>
            <!-- <van-icon name="edit" /> -->
          </div>
        </div>
      </div>
    
      <div class="piao-box flexcenter">
        <div class="item">
          <div class="title">账户余额</div>
          <div class="value">{{userInfo.now_money}}</div>
          <img class="bonus-img" mode="widthFix" src="~images/myinfo/bonus.png">
        </div>
        <div class="item">
          <div class="title">优惠券</div>
          <div class="value">0</div>
          <img class="coupon-img" mode="widthFix" src="~images/myinfo/coupon.png">
        </div>
      </div>
    </div>
  
    <div class="cont-box">
      <div class="wdspdd">
        <div class="up flexcenter">
          <div class="l1 f32b">我的商品订单</div>
          <div class="r1 flexcenter" @click="goto('myorders','')">全部订单 <span>></span></div>
        </div>
        <div class="down flexcenter">
          <div class="item flexcenter" @click="goto('myorders','1')">
            <img class="down-img"  src="~images/myinfo/daifukuan.png">
            <div>待付款</div>
          </div>
        
          <div class="item flexcenter" @click="goto('myorders','2')">
            <img class="down-img"  src="~images/myinfo/daifahuo.png">
            <div>待发货</div>
          </div>
        
          <div class="item flexcenter" @click="goto('myorders','3')">
            <img class="down-img"  src="~images/myinfo/daishouhuo.png">
            <div>待收货</div>
          </div>
        
          <div class="item flexcenter" @click="goto('myorders','4')">
            <img class="down-img"  src="~images/myinfo/shouhou.png">
            <div>已完成</div>
          </div>
        </div>
      </div>
    
      <div class="myservice">
        <div class="title f32b">我的服务</div>
        <van-cell-group>
          <van-cell data-idx="0" title="门票订单" icon="newspaper-o" is-link @click="goto('mytickets')"/>
          <van-cell data-idx="2" title="地址信息" icon="location-o" is-link @click="goto('myaddress')"/>
          <van-cell data-idx="3" title="我的推广" icon="label-o" is-link @click="goto('mypush')"/>
          <van-cell data-idx="4" title="优惠券" icon="coupon-o" is-link @click="goto('mycoupons')"/>
          <van-cell data-idx="5" title="关于我们" icon="info-o" is-link @click="goto('aboutus')"/>
		  <van-cell data-idx="6" title="退出登录" icon="info-o" is-link @click="logout()"/>
        </van-cell-group>
      </div>
    </div>
	
	<van-tabbar route active-color="#FF8A00">
	  <van-tabbar-item replace to="/" icon="home-o">首页</van-tabbar-item>
	  <van-tabbar-item replace to="/products" icon="search">商品</van-tabbar-item>
	  <van-tabbar-item replace to="/cart" icon="cart-o">购物车</van-tabbar-item>
	  <van-tabbar-item replace to="/myinfo" icon="manager-o">我的</van-tabbar-item>
	</van-tabbar>
  </div>
</template>

<script>
  export default {
    name: 'myinfo',
    data() {
      return {
        daoyou:'0',
        userInfo:{},
      }
    },
    created() {
      let that = this;
      let user_level = localStorage.getItem('user_level');
      if(user_level=='3'||user_level=='4') {
        this.daoyou = 1
      }
      
	  this.post(this.API.Ucenter,{})
		  .then( res => {
			  that.userInfo = res.data
		  })
		
		upsdk.pluginReady(function () {
		   upsdk.setTitleStyle({
			  // 可选，导航栏(含状态栏)背景色及透明度。16进制，前2位(8F)透明度，后六位(FFFFFF)颜色，仅对当前页有效，其余页还是申请配置的导航默认颜色
			  navBackgroundColor:'0xFFFFE143',
			  appletStyle: 'black', //可选，black-黑色主题，white-白色主题
			  appletTitleBarVisible: '1',// 可选，标题栏是否显示。'0'不显示，'1'显示，默认显示y
			  backBtnVisible: '1', // 可选，左侧返回按钮是否显示。'0'不显示，'1'显示，不传或空则默认显示
			});
		});
    },
    methods: {
      /**
       * 跳转
       *
       */
      goto(name,status) {
		  console.log(status);
        this.$router.push({
			name:name,
			query:{
			        type:status
			    }
		})
      },
      /**
       * 跳转
       *
       */
      contact() {
        console.log('联系客服')
      },
	  logout() {
		
		window.localStorage.setItem('userToken','');
		window.localStorage.setItem('user_id','');
		window.localStorage.setItem('user_level','');
		window.localStorage.setItem('is_guider','');
		
		this.$router.replace({
		  name: 'Home',
		})
	  },
      /**
       * 请先登录
       */
      goLogin:function(){
        let that = this;
        this.post(
          'index.php/wap/weapp.Ucenter/index',{}
        ).then(response => {
          this.userInfo = response.data
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  /**index.wxss**/
  .page {
    height: 100%;
    background-color: #F5F5F5;
  }
  .head {
    box-sizing: border-box;
    padding: 24px;
    height: 330px;
    background-image: linear-gradient(4deg, #FFAF01 0%, #F8D414 46%, #FFE143 100%);
  }
  .user {
    margin-bottom: 56px;
    padding-left: 38px;
  }
  .user .user-img {
    margin-right: 24px;
    width: 84px;
    height: 84px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0 12px 16px 1px #EFC52B;
  }
  .user .r1 {
    color: #191919;
  }
  .user .r1 .name {
    font-size: 36px;
    font-weight: bold;
  }
  .user .r1 .id {
    font-size: 30px;
  }
  .user .r1 .id .iconfont {
    margin-left: 10px;
    font-size: 32px;
  }

  .piao-box {
    justify-content: space-between;
  }
  .piao-box .item {
    position: relative;
    box-sizing: border-box;
    padding: 24px;
    width: 339px;
    height: 142px;
    border-radius: 6px;
    background-color: #FFFFFF;
  }
  .piao-box .item .title {
    margin-bottom: 10px;
    font-size: 30px;
    color: #777777;
  }
  .piao-box .item .value {
    font-size: 38px;
    font-weight: bold;
    color: #191919;
  }
  .piao-box .item .bonus-img {
    position: absolute;
    width: 95px;
    right: 0;
    bottom: 0;
  }
  .piao-box .item .coupon-img {
    position: absolute;
    width: 100px;
    right: 0;
    bottom: 0;
  }

  .cont-box {
    margin: 24px;
    /*height: 100%;*/
  
  }
  .wdspdd, .myservice {
    box-sizing: border-box;
    margin-bottom: 24px;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 8px;
  }
  .wdspdd .up {
    justify-content: space-between;
    margin-bottom: 30px;
    padding-bottom: 24px;
    border-bottom: 1px solid #EEEEEE;
  }
  .wdspdd .up .r1 {
    justify-content: center;
    width: 152px;
    height: 40px;
    /*line-height: 40px;*/
    border-radius: 20px;
    text-align: center;
    background-color: #FFEDCF;
    color: #FF8A00;
    font-size: 22px;
  }
  .wdspdd .down {
    justify-content: space-between;
  }
  .wdspdd .down .item {
    flex-direction: column;
    width: 170px;
    font-size: 28px;
    color: #191919;
  }
  .wdspdd .down .item .down-img {
    margin-bottom: 5px;
    width: 40px;
    height: 40px;
  }

  .myservice .title {
    margin-bottom: 24px;
  }

</style>
